import React, { Component } from "react";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { Home, Person, HeadsetMic } from "@material-ui/icons";
import GridCellsView from "./GridCell";
import GroupList from "./GroupList";
import GroupDetailPage from "./GroupDetailPage";
import "./App.css"; // 导入CSS模块
import { eventBus } from "./EventBus";
import { NormalFriendGridCell, VipFriendGridCell } from "./NewFriendGridCell";


const { APP_ID } = require("./config.json");
//const REDIRECT_URI = 'https://6ba61450.ngrok.io';
//const REDIRECT_URI = 'http://192.168.2.146:3000';

const GET_CODE_URL = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APP_ID}&redirect_uri=https%3A%2F%2Fforlifejj.cn%2F&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;

var _this;

class App extends Component {

  state = {
    authed: false, //TODO:elvissu
    stepMsg: "",
    headimgurl:
      "https://img1.baidu.com/it/u=3685234108,954911792&fm=253&fmt=auto&app=138&f=GIF?w=200&h=200",
    fetchPayResultTimerId: null,
    orderId: "",
    groupName: "",
    groupQRCode: "",
    curPage: "home",
    jumpedDetailPage: false,
    gridCellData: null,
    groupId: null,
  };

  componentDidMount() {
    _this = this;
    eventBus.addListener(
      "EVENT_group_detail_click",
      this.handleGroupDetailClick
    );
    eventBus.addListener(
      "EVENT_pay_click",
      this.onHandlePayClick
    );
    eventBus.addListener(
      "EVENT_on_back_click",
      this.handleOnBackClick
    );
    this.checkAuth();
    window.addEventListener('popstate', this.handleOnBackClick, false); // false阻止默认事件
  }

  componentWillUnmount() {
    eventBus.removeAllListeners();
  }

  handleOnBackClick() {
    history.pushState(null, null, document.URL);
    _this.setState({
      jumpedDetailPage: false,
    });
  }

  handleGroupDetailClick(gridCellData) {
    console.log("elvissss" + gridCellData.name);
    const { authed } = _this.state;
    if (!authed) {
      alert("请您先同意微信登录授权后使用。");
      _this.checkAuth();
      return;
    }
    //history.pushState(null, null, document.URL);
    _this.setState({
      jumpedDetailPage: true,
      gridCellData: gridCellData,
    });
  }

  onHandlePayClick() {
    _this.setState({
      groupId: _this.state.gridCellData.id
    });
    _this.getOrderNum();
  }

  // 设置 cookie 函数
  setCookie(name, value) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 7);
    document.cookie = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/`;
  }

  render() {
    const { jumpedDetailPage } = this.state;

    if (jumpedDetailPage) {
      const gridCellData = this.state.gridCellData;
      return <GroupDetailPage gridCellData={gridCellData} />;
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 20,
          }}
        >
          <img
            className="topbarimg"
            src="https://friends-1309332560.cos.ap-guangzhou.myqcloud.com/friends/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20240619125630.jpg"
            alt=""
          />
        </div>

	{ /* <GridCellsView></GridCellsView>
        <GroupList></GroupList> */}
        
        <NormalFriendGridCell/>
        <VipFriendGridCell/>

	<div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 20,
          }}
        >
          <img
            className="topbarimg"
            src="https://friends-1309332560.cos.ap-guangzhou.myqcloud.com/friends/%E6%90%AD%E5%AD%90%E4%BA%A4%E5%8F%8B%E7%BE%A4%E4%BB%8B%E7%BB%8D%E6%9B%BF%E6%8D%A2.jpg"
            alt=""
          />
        </div>
        {/* <BottomNavigation
          value={this.state.curPage}
          onChange={(event, newValue) => {
            this.setState({
              curPage: newValue,
            });
          }}
          showLabels
          style={{
            width: "100%",
          }}
          showLabels
          style={{
            width: "100%",
            position: "fixed",
            bottom: 0,
          }}
        >
          <BottomNavigationAction label="主页" value="home" icon={<Home />} />
          <BottomNavigationAction
            label="客服"
            value="server"
            icon={<HeadsetMic />}
          />
          <BottomNavigationAction label="我的" value="mine" icon={<Person />} />
        </BottomNavigation> */}
      </div>
    );
  }

  doSvrLogin = (code) => {
    const data = {
      appid: 1000001,
      login_type: 1,
      token: code,
    };

    // 将数据转换为JSON字符串
    const jsonData = JSON.stringify(data);

    // fetch请求（这里不传递证书，因为fetch不支持）
    fetch("https://forlifejj.cn/svr/proc/login", {
      method: "POST", // 或者 'POST'
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
        // 注意：这里不能设置证书，因为fetch API不支持
      },
      body: jsonData, // 请求体为JSON字符串
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to login");
        }
        return response.json();
      })
      .then((result) => {
        console.log("elvissssss：" + result.RspBody);
	if(result.RspBody.length === 0) {
           alert("后台登录失败，请稍后重试.")	   
	   return;
	}
        history.pushState(null, null, document.URL);
        // 假设登录成功，更新组件状态
	const rspBodyObj = JSON.parse(result.RspBody);
        this.setCookie("appid",1000001);
	this.setCookie("uid",rspBodyObj.uid);
	this.setCookie("login_type",1);
	this.setCookie("ticket",rspBodyObj.ticket);
	this.setCookie("openid",rspBodyObj.openid);
	this.setState({
          ...result, // 根据返回的数据结构更新状态
          authed: true,
        });
      })
      .catch((error) => {
        console.error("Error during login:", error);
        // 可能需要在这里设置组件状态以反映错误情况
      });
  };

  getOrderNum = () => {
    const data = {};

    // 将数据转换为JSON字符串
    const jsonData = JSON.stringify(data);

    // fetch请求（这里不传递证书，因为fetch不支持）
    fetch("https://forlifejj.cn/svr/proc/create_orderid", {
      method: "POST", // 或者 'POST'
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
      },
      body: jsonData, // 请求体为JSON字符串
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to getOrder");
        }
        return response.json();
      })
      .then((result) => {
        console.log("elvissssss getOrder：" + result.RspBody);
        const rspBodyObj = JSON.parse(result.RspBody);
        this.setState({
          orderId: rspBodyObj.orderId,
        });
        this.getPayInfo();
      })
      .catch((error) => {
        //eventBus.emit("Event_pay_result", 0, "test", "https://img1.baidu.com/it/u=3685234108,954911792&fm=253&fmt=auto&app=138&f=GIF?w=200&h=200");
        console.error("Error during getOrder:", error);
        // 可能需要在这里设置组件状态以反映错误情况
      });
  };

  getPayInfo = () => {
    const rspBodyObj = JSON.parse(this.state.RspBody);

    const data = {
      openid: rspBodyObj.openid,
      amount: Math.round(this.state.gridCellData.price*100),
      order_id: this.state.orderId,
      group_id: `${this.state.groupId}`,
    };

    const jsonData = JSON.stringify(data);

    fetch("https://forlifejj.cn/svr/proc/wxprepay", {
      method: "POST", // 或者 'POST'
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
      },
      body: jsonData, // 请求体为JSON字符串
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to getpayInfo");
        }
        return response.json();
      })
      .then((result) => {
        console.log("elvissssss：" + result.RspBody);
        const rspBodyObj = JSON.parse(result.RspBody);
        this.setState({
          stepMsg: "正在拉起微信支付",
        });
        this.onCallWechatPay(rspBodyObj);
      })
      .catch((error) => {
        console.error("Error during getPayInfo:", error);
        // 可能需要在这里设置组件状态以反映错误情况
      });
  };

  onCallWechatPay = (orderInfo) => {
    var _this = this;
    if (
      typeof WeixinJSBridge === "object" &&
      typeof WeixinJSBridge.invoke === "function"
    ) {
      WeixinJSBridge.invoke("getBrandWCPayRequest", orderInfo, function (res) {
        if (res.err_msg == "get_brand_wcpay_request:ok") {
          // 使用以上方式判断前端返回,微信团队郑重提示：
          //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          console.log("elvissssssss wechat pay success!");

          let timerId = setInterval(function () {
            _this.checkPayResult();
            console.log("elvissssssss 查询支付结果");
          }, 2000);
          _this.setState({
            stepMsg: "正在查询支付结果",
            fetchPayResultTimerId: timerId,
          });
        } else {
          _this.setState({
            stepMsg: "支付失败！",
          });
          eventBus.emit("Event_pay_result", -1);
          console.log("elvissssssss wechat pay: " + res.err_msg);
        }
      });
    }
  };

  //   curl -H "Content-Type: application/json"      -X POST -d '{"orderId":"1716107637292512580"}'     http://forlifejj.cn/svr/test/query_order_status

  // 无效的订单号或者支付失败的订单号
  // {"Code":0,"Message":"SUCC","RspBody":"{\"bizInfo\":\"\",\"maxPollTimes\":60,\"needPoll\":0,\"pollInterval\":1000,\"status\":2}"}

  // 支付成功的订单号
  // {"Code":0,"Message":"SUCC","RspBody":"{\"bizInfo\":\"{\\\"group_id\\\":\\\"1\\\",\\\"group_name\\\":\\\"密友交友群\\\",\\\"group_avatar\\\":\\\"\\\",\\\"group_qr_code\\\":\\\"xxx\\\",\\\"group_members\\\":0}\",\"maxPollTimes\":60,\"needPoll\":0,\"pollInterval\":1000,\"status\":2}"}

  checkPayResult = () => {
    const data = {
      orderId: this.state.orderId,
    };

    const jsonData = JSON.stringify(data);

    fetch("https://forlifejj.cn/svr/proc/query_order_status", {
      method: "POST", // 或者 'POST'
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
      },
      body: jsonData, // 请求体为JSON字符串
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to checkPayResult");
        }
        return response.json();
      })
      .then((result) => {
        console.log("elvissssss：" + result.RspBody);
        const rspBodyObj = JSON.parse(result.RspBody);
        if (rspBodyObj.needPoll === 0) {
          console.log("elvissssss stop poll");
          clearInterval(this.state.fetchPayResultTimerId);
        }
        if (typeof rspBodyObj.bizInfo != "undefined") {
          console.log("elvissssss get bizInfo");
          if (rspBodyObj.bizInfo.length > 0) {
            const bizInfoObj = JSON.parse(rspBodyObj.bizInfo);
            this.setState({
              groupName: bizInfoObj.group_name,
              groupQRCode: bizInfoObj.group_qr_code,
              stepMsg: "获取支付结果：支付成功",
            });
            eventBus.emit("Event_pay_result", 0, bizInfoObj.group_name, bizInfoObj.group_qr_code);
          } else {
            this.setState({
              stepMsg: "获取支付结果：支付失败",
            });
            eventBus.emit("Event_pay_result", -2);
          }
        }
      })
      .catch((error) => {
        console.error("Error during getPayInfo:", error);
        // 可能需要在这里设置组件状态以反映错误情况
      });
  };

  checkAuth = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    if (!code) {
      window.location.href = GET_CODE_URL;
    } else {
      this.setState({ code });
      this.doSvrLogin(code);
    }
  };
}
export default App;
