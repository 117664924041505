import React, { Component } from "react";
import "./GroupDetailPage.css";
import NavTopBar from "./NavTopBar";
import { eventBus } from "./EventBus";
import ReactLoading from "react-loading";

class GroupDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gridCellData: props.gridCellData,
      showLoading: false,
    };
    this.toggleLoadingView = this.toggleLoadingView.bind(this);
  }

  toggleLoadingView(isShow) {
    this.setState({
      showLoading: isShow,
    });
  }

  render() {
    return (
      <div>
        {this.state.showLoading ? (
          <div className="payloading">
            <ReactLoading type="spin" color="#34b449" height={80} width={80} />
          </div>
        ) : null}
        <NavTopBar title={this.state.gridCellData.name} />
        <GroupDetailContent
          gridCellData={this.state.gridCellData}
          toggleLoadingView={this.toggleLoadingView}
        />
      </div>
    );
  }
}

class GroupDetailContent extends React.Component {
  constructor(props) {
    super(props);
    var randomNum = Math.floor(Math.random() * 6) + 1;
    var qunIconUrl = `https://friends-1309332560.cos.ap-guangzhou.myqcloud.com/friends/qun${randomNum}.jpg`;
    this.onPayClick = this.onPayClick.bind(this);
    this.onPayResult = this.onPayResult.bind(this);
    this.state = {
      hasPaySuc: false,
      groupName: props.gridCellData.name,
      groupQrUrl: "https://friends-1309332560.cos.ap-guangzhou.myqcloud.com/friends/qun1.jpg",
      gridCellData: props.gridCellData,
      qunIconUrl: qunIconUrl,
    };

    eventBus.addListener("Event_pay_result", this.onPayResult);
  }

  componentWillUnmount() {
    eventBus.removeListener("Event_pay_result", this.onPayResult);
  }

  onPayClick() {
    console.log("on pay click" + this.state.gridCellData.id);
    eventBus.emit("EVENT_pay_click");
    this.props.toggleLoadingView(true);
  }

  onPayResult(result, groupName, groupQrUrl) {
    this.props.toggleLoadingView(false);
    if (result === 0) {
      this.setState({
        hasPaySuc: true,
        groupName: groupName,
        groupQrUrl: groupQrUrl,
      });
    }
  }

  render() {
    if (this.state.hasPaySuc) {
      return (
        <div className="content">
          <div className="novipp">
            <div style={{ marginTop: "0rem" }}>
              <p style={{color:"#9258f2"}}>{this.state.groupName} 支付成功,请扫码进群</p>
            </div>
          </div>
          <img
            className="paysucqr"
            src={this.state.groupQrUrl}
            alt=""
          ></img>
        </div>
      );
    }

    return (
      <div className="content">
        <img
          className="novipqrbg"
          src="https://friends-1309332560.cos.ap-guangzhou.myqcloud.com/friends/novip.jpg"
          alt=""
        ></img>
        <div className="novipt">
          <img className="qunicon" src={this.state.qunIconUrl} alt="" />
          <span>{this.state.gridCellData.name}</span>
        </div>
        <div className="novipp">
          <div style={{ marginTop: "8rem" }}>
            支付<span>{this.state.gridCellData.price}</span>元后可进群
            <p onClick={this.onPayClick}>点击支付</p>
          </div>
        </div>
      </div>
    );
  }
}

export default GroupDetailPage;
