import React, { Component } from "react";
import "./NavTopBar.css";
import { eventBus } from "./EventBus";

class NavTopBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.title ?? "标题",
    };
  }

  onBackClick() {
    eventBus.emit("EVENT_on_back_click");
  }

  render() {
    return (
      <div className="topnavheader">
        <img onClick={this.onBackClick}
          className="topnavimg"
          src="https://friends-1309332560.cos.ap-guangzhou.myqcloud.com/friends/return.png"
          alt=""
        />
        <p>{this.state.title}</p>
      </div>
    );
  }
}

export default NavTopBar;
