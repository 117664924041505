import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Avatar from "@material-ui/core/Avatar";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import "./GroupList.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

class GroupListData {
  constructor(url, name) {
    this.url = url;
    this.name = name;
  }
}

export default function GroupList() {
  const classes = useStyles();
  var dataList = [
    new GroupListData(
      "https://friends-1309332560.cos.ap-guangzhou.myqcloud.com/friends/qun1.jpg",
      "深圳佳丽交友群"
    ),
    new GroupListData(
      "https://friends-1309332560.cos.ap-guangzhou.myqcloud.com/friends/qun2.jpg",
      "广州本地交友群"
    ),
    new GroupListData(
      "https://friends-1309332560.cos.ap-guangzhou.myqcloud.com/friends/qun3.jpg",
      "东莞高端佳丽群"
    ),
    new GroupListData(
      "https://friends-1309332560.cos.ap-guangzhou.myqcloud.com/friends/qun4.jpg",
      "吃瓜1群"
    ),
    new GroupListData(
      "https://friends-1309332560.cos.ap-guangzhou.myqcloud.com/friends/qun5.jpg",
      "吃瓜2群"
    ),
    new GroupListData(
      "https://friends-1309332560.cos.ap-guangzhou.myqcloud.com/friends/qun6.jpg",
      "高端吃瓜3群"
    ),
  ];

  var cellList = [];

  for (let index = 0; index < dataList.length; index++) {
    const element = dataList[index];
    var randomNum = Math.floor(495 + (500 - 495)* Math.random());
    var randomStr = `${randomNum}/500`
    cellList.push(
      <ListItem>
        <ListItemAvatar>
          <Avatar className={classes.large} variant="square">
            <img className="homegrouplistimg" src={element.url} alt="" />
          </Avatar>
        </ListItemAvatar>
        <div style={{width:10}}></div>
        <ListItemText primary={element.name} secondary={randomStr} />  
      </ListItem>
    );
  }

  return (
    <List className={classes.root}>
        {cellList}
    </List>
  );
}
