import React from "react";
import "./App.css";
import "./GridCell.css";
import { eventBus } from "./EventBus";
import { GridCellData } from "./GridCell";

class NormalFriendGridCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: new GridCellData(
        1,
        "https://friends-1309332560.cos.ap-guangzhou.myqcloud.com/friends%2Fmmexport1716970437593.jpg",
        "搭子交友群",
        "830e818a1abb1b0c684cc50523cf7632",
        19.9
      ),
    };
  }

  handleClick = () => {
    eventBus.emit("EVENT_group_detail_click", this.state.data);
  };

  render() {
    return (
      <img
        onClick={this.handleClick}
        className="topbarimg"
        src="https://friends-1309332560.cos.ap-guangzhou.myqcloud.com/friends/%E6%90%AD%E5%AD%90%E4%BA%A4%E5%8F%8B%E7%A0%81%E6%9B%BF%E6%8D%A2.jpg"
        alt=""
      />
    );
  }
}

class VipFriendGridCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: new GridCellData(
        2,
        "https://friends-1309332560.cos.ap-guangzhou.myqcloud.com/friends%2Fmmexport1716970454039.jpg",
        "高质量搭子群",
        "88b1b369b60f163c76072261a252f0c4",
        29.9
      ),
    };
  }

  handleClick = () => {
    eventBus.emit("EVENT_group_detail_click", this.state.data);
  };

  render() {
    return (
      <img
        onClick={this.handleClick}
        className="topbarimg"
        src="https://friends-1309332560.cos.ap-guangzhou.myqcloud.com/friends/%E9%AB%98%E8%B4%A8%E9%87%8F%E6%90%AD%E5%AD%90%E7%A0%81%E6%9B%BF%E6%8D%A2.jpg"
        alt=""
      />
    );
  }
}

export { NormalFriendGridCell, VipFriendGridCell };

